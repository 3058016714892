const howHeardList = [
  "Facebook",
  "Instagram",
  "LinkedIn",
  "Google",
  "Campus Recruitment",
  "Word of Mouth",
  "Other",
];
const howHeard = howHeardList.map((v) => ({ label: v, value: v }));
export default howHeard;
